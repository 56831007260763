@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Exo+2&display=swap");

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$green: #008755;
$dark: #002d20;
$blue: #0081b6;

$green_guzel: (
  100: #004a21,
  500: #007749,
  700: #46a775,
  contrast: (
    100: $dark-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  )
);


$els-primary: mat-palette($green_guzel);
$els-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$els-warn: mat-palette($mat-orange);

// Create the theme object (a Sass map containing all of the palettes).
$els-theme: mat-light-theme($els-primary, $els-accent, $els-warn);


$els-typography: mat-typography-config(
  $font-family: '"Exo 2", sans-serif'
);

@include angular-material-typography($els-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($els-theme);

html,
body {
  min-height: 100vh;
  margin: 0; 
}
body {
  background-color: $dark;
  background-image: -ms-linear-gradient    (    -70deg,    $dark 5%,    $green 45%,    $blue 100%  );
  background-image: -webkit-linear-gradient(    -70deg,    $dark 5%,    $green 45%,    $blue 100%  );
}

.mat-app-background, .mat-drawer-container {
  background: none;
}

.login-css .mat-dialog-container {
  background: transparent;
  color: white;

  box-shadow: none;
  .mat-dialog-actions{
  .mat-button {
    background: white;
    color: $green;
    border-radius: 2em;
  }
  }
  .mat-form-field > input {
    color: white;
  }
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: white;
  }


  .mat-input-element {
    background-color: transparent;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: white;
  }
}

.mat-raised-button, .mat-button {
  border-radius: 2em !important;
}

footer {
  z-index: 1000;
}

.mat-icon {
  $foreground: map-get($els-theme, foreground);
  color: mat-color($foreground, secondary-text);
}

.actions_col {
  flex: 0.8;
  padding: 0 4px;
}

.select_col {
  flex: 0.25;
  padding: 0 4px;
}

.table_checkbox {
  padding-top: 8px;
}

.padding_right{
  padding-right: 12px;
}

.center{
  text-align: center;
}

.mat-card {
   margin: 16px;
}
